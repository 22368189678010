<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>订单</el-breadcrumb-item><el-breadcrumb-item>平台订单管理</el-breadcrumb-item><el-breadcrumb-item>平台订单列表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddMerchantClick"><el-icon><edit/></el-icon>添加商户</el-button> -->
        <!-- <el-button type="primary" @click="onAddShopClick"><el-icon><edit/></el-icon>添加店铺</el-button> -->
        <!-- <el-button type="primary" size="default" :icon="search" @click="onAddClick">添加商户</el-button> -->
        <!-- <Test1/> -->
      </div>
    </el-header>
    <!-- 其他窗体 START -->
    <merchants-edit-view ref="refMerchantUpdateView" @submit="onEditSubmit" />
    <shop-edit-view ref="refShopUpdateView" @submit="onEditSubmit" />
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <!-- <div class="ep-table-header"> -->
      <!-- 搜索栏 START -->
      <el-form class="ep-search-form" ref="searchForm" :model="searchModel" label-width="100px">
        <div class="m-common-field">
          <div class="ep-search-form-item-row">
            <!-- <div style="width:420px">
              <el-form-item label="订单搜索：">
                <el-input placeholder="请输入内容" v-model="searchContent" class="es-input-with-select">
                  <template #prepend>
                    <el-select v-model="searchKey" placeholder="请选择">
                      <el-option label="商户名称" value="merchant_name" />
                      <el-option label="商户编码" value="merchant_code" />
                    </el-select>
                  </template>
                </el-input>
              </el-form-item>
            </div> -->
            <div>
              <el-form-item label="创建时间：">
                <el-date-picker v-model="searchModel.createdTime" type="datetimerange" :picker-options="pickerOptions"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
                </el-date-picker>
              </el-form-item>
            </div>
            
          </div>
        </div>
        <div class="ep-search-form-button-row">
          <!-- <el-button type="primary" @click="onSearchClick">搜索</el-button> -->
          <!-- <el-button @click="onAdvanceToggle">高级搜索</el-button> -->
        </div>
    
      </el-form>
      <!-- 搜索栏 END -->

      <el-table ref="esDataTable" fit size="small" v-loading="esIsLoading" default-expand-all
        :data="esDataList" :height="esFixedTableHeight" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        :row-class-name="funRowClassName"
        row-key="entity_id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="onTableSelectionChange"> <!-- @row-click="onTableRowClick" -->

        <!-- <el-table-column type="selection" width="50" /> -->
        <el-table-column label="类型" min-width="100" ><!-- fixed -->
          <template #default="scope">
            <template v-if="scope.row.entity_type == 'merchant'">商户</template><template v-else>店铺</template>
          </template>
        </el-table-column>

        <el-table-column label="名称" min-width="200">
          <template #default="scope">
            <template v-if="scope.row.entity_type == 'merchant'">{{ scope.row.merchant_name }}</template>
            <template v-else><source-code-tag :value="scope.row.source_code" /><span style="padding-left:5px;">{{ scope.row.shop_name }}</span></template>
            <div class="es-cell-subtitle">
              <template v-if="scope.row.entity_type == 'merchant'">{{ scope.row.merchant_id }}</template><template v-else>{{ scope.row.shop_id }}</template>
            </div>
            <!-- <div v-if="scope.row.a">
              <span class="es-action-button primary" @click="onDetailClick(scope.row)">查看详情</span>
              <span class="ep-action-line"/>
              <span class="es-action-button primary" @click="onUpdateClick(scope.row)">编辑</span>
              <span class="ep-action-line"/>
              <span class="es-action-button danger" @click="onDeleteClick(scope.row.merchant_id)">删除</span>
            </div> -->
          </template>
        </el-table-column>

        <el-table-column label="编码" min-width="100">
          <template #default="scope">
            <template v-if="scope.row.entity_type == 'merchant'">{{ scope.row.merchant_code }}</template><template v-else>{{ scope.row.shop_code }}</template>
          </template>
        </el-table-column>

        <el-table-column label="平台店铺ID" min-width="100">
          <template #default="scope">
            <template v-if="scope.row.entity_type == 'shop'">{{ scope.row.ref_key }}</template>
          </template>
        </el-table-column>

        <el-table-column label="状态" min-width="150">
          <template #default="scope">
            <template v-if="scope.row.entity_type == 'shop'">
              <template v-if="scope.row.shop_state == '1'"><EpStateTag type="success" effect="light" label="正常"/></template>
              <template v-else><EpStateTag type="info" effect="light" label="冻结"/></template>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="150" fixed="right">
          <template #default="scope">

            <template v-if="scope.row.entity_type == 'shop'">
              <template v-if="scope.row.source_code == 'WeimobPlatform'">


                <span class="es-action-button primary" @click="onOrderListClick(scope.row)">查看平台订单</span>
                <span class="ep-action-line"/>
                <span class="es-action-button primary" @click="onDetailClick(scope.row)">搜索</span>

              </template>
              
              <div>
                <span class="es-action-button primary" @click="onCatchClick([scope.row.shop_id])">抓取</span>
              </div>  

            </template>

            

            
            <!-- <span class="ep-action-line"/>
            <span class="es-action-button primary" @click="onUpdateClick(scope.row)">编辑</span>
            <span class="ep-action-line"/>
            <span class="es-action-button danger" @click="onDeleteClick(scope.row.merchant_id)">删除</span> -->
            <!-- <div> -->
              <!-- <el-dropdown @click="onMoreButtonClick">
                <span class="es-action-button primary">更多<el-icon class="el-icon--right"><arrow-down /></el-icon></span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{index: 'redeclare', id: scope.row.order_id}">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown> -->
            <!-- </div> -->
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="ep-page-footer">
      <div class="ep-page-footer-toolbar">
        <!-- <el-button type="primary" @click="onAddClick"><el-icon><edit/></el-icon>添加商户</el-button> -->
      </div>
      <div class="ep-page-footer-pager">
        <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import BasePageMixin from '@/mixin/BasePageMixin.js'
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown } from '@element-plus/icons-vue'
import MerchantsEditView from '@/views/v1/merchant/merchants/MerchantsEditView'
import ShopEditView from '@/views/v1/merchant/shop/ShopEditView'
import MerchantShopSearchView from '@/views/v1/merchant/merchant_shop/list/MerchantShopSearchView'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import EpStateTag from '@/components/v1/common/EpStateTag'
import { apiV1GetMerchantShopPage } from '@/api/merchant.js' // apiDeleteMerchants
import { apiOrdersActionCatch } from '@/api/trade.js'



// import { Test1 } from '@/pages/test/Test1'

export default {
  mixins: [ BasePageMixin, DataTableMixin ],
  name: "shop-list-page",
  components: {
    // Edit, 
    // ArrowDown,
    MerchantsEditView,
    ShopEditView,
    MerchantShopSearchView,
    SourceCodeTag,
    EpStateTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',

      current_merchant_id: '',
      // 当前店铺id
      current_shop_id: '', // 32201908210407394928150064e55678

      activeName: 'first',
      epPageSize: 10,
      searchModel: {
        createdTime: [],
      },
      // 时间选择快捷方式
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {},
  methods: {
    getTableHeightFix() {
      return (typeof(this.$refs.searchView) === 'undefined') ? 290 : (176 + this.$refs.searchView.getViewHeight())
    },
    getFilter() {
      let data = {}
      if (this.current_shop_id) {
        data = {shop_id: this.current_shop_id }
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      // let searchData = this.$refs.searchView.getSearchFilter() , searchData
      let queryData = Object.assign(paginationData, filterData)

      this.esIsLoading = true

      apiV1GetMerchantShopPage(queryData).then((res) => {
        this.esDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.esIsLoading = false
      })
    },

    // ===================================================================================================

    // 点击 添加商户
    onAddMerchantClick(id) {
      // this.getPage()
      this.$refs.refMerchantUpdateView.showView({ editMode: "insert" })
    },
    // 点击 添加店铺
    onAddShopClick(id) {
      // this.getPage()
      this.$refs.refShopUpdateView.showView({ editMode: "insert" })
    },
    // 查看平台订单
    onOrderListClick(rowObj) {
      var newUrl = ''
      if (rowObj.source_code == 'WeimobPlatform') {
        newUrl = this.$router.resolve({ name: 'WeimobOrderListPage', params: { id: rowObj.shop_id } })
      }
      //  else {
      //   newUrl = this.$router.resolve({ name: 'ShopDetailPage', params: { id: rowObj.shop_id } })
      // }
      
      window.open(newUrl.href, '_blank')
    },
    // 搜索订单页面
    onDetailClick(rowObj) {
      let newUrl = this.$router.resolve({ name: 'WeimobOrderDetailPage2', params: { id: rowObj.shop_id } })
      
      window.open(newUrl.href, '_blank')
    },


    // 点击修改
    // onUpdateClick(rowObj) {
    //   if (rowObj.entity_type == 'merchant') {
    //     this.$refs.refMerchantUpdateView.showView({ editMode: "update", id: rowObj.merchant_id })
    //   } else {
    //     this.$refs.refShopUpdateView.showView({ editMode: "update", id: rowObj.shop_id })
    //   }
    // },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiDeleteMerchants({
      //     merchant_id: id,
      //   }).then((res) => {
      //     this.getPage()
      //     this.$message.success("操作成功")
      //   })
      // }).catch(()=>{})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      this.$message.success("操作成功")
    },
    // 点击搜索后触发
    onSearched(data) {
      this.getPage()
    },
    onSearchToggle() {
      this._esSetTableHeight()
    },

    // 更多按钮触发
    onMoreButtonClick(commandObj) {
      // console.log(commandObj)
      switch (commandObj.index) {
        case 'redeclare':
          this.onDeclareClick([commandObj.id], 1)
          break;
      }
    },

    // 点击 抓取 按钮触发
    onCatchClick(inIds = []) {
      console.log(this.searchModel.createdTime); // return
      var ids = []
      if (inIds.length > 0) {
        ids = inIds
      } else {
        var selection = this.getMultipleSelection()
        selection.forEach((value) => {
          ids.push(value.order_id)
        });
      }

      var sendData = {
        ids: ids,
      }

      if (this.searchModel.createdTime != null) {
        var start_created = parseInt(this.searchModel.createdTime[0].getTime() / 1000)
        var end_created = parseInt(this.searchModel.createdTime[1].getTime() / 1000)

        sendData.start_created = start_created
        sendData.end_created = end_created
      }

      console.log(sendData);
      // return

      apiOrdersActionCatch(sendData).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },




    // ===================================================================================================

    // 实体行样式
    funRowClassName({  row,  rowIndex,}) {
      if (row.entity_type === 'merchant') {
        return 'jxgocs-merchant-row'
      } else if (row.entity_type === 'shop') {
        return 'jxgocs-shop-row'
      }
      return ''
    },


  },
  watch: {},
  mounted() {
    // 初始化时间 最近24小时
    const createdTimeStart = new Date();
    createdTimeStart.setTime(createdTimeStart.getTime() - 3600 * 1000 * 24);
    this.searchModel.createdTime = [createdTimeStart, new Date()]

    this.$nextTick(() => {
      this.getPage()
    })
  }
}
</script>

<style scoped>
</style>
