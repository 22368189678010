<template>
  <el-drawer v-model="viewVisible" direction="rtl" size="800px" custom-class="ep-drawer" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">
    <template #title><span>编辑：商户</span></template>
    <div class="ep-drawer-wrap">
      <div class="ep-drawer-panel">
        <el-form ref="form" :model="formModel" label-width="200px" v-loading="isLoading" status-icon :rules="formRules">
          <div class="ep-drawer-region-title">基础信息</div>
          <div class="ep-drawer-region-wrap">
            <el-form-item label="商户名称：" prop="merchant_name">
              <el-input v-model="formModel.merchant_name" />
            </el-form-item>
            <el-form-item label="商户编码：" prop="merchant_code">
              <el-input v-model="formModel.merchant_code" />
            </el-form-item>
            <el-form-item label="关联店铺ID：" prop="ref_key">
              <el-input v-model="formModel.ref_key" />
            </el-form-item>
            <el-form-item label="商户状态：" prop="merchant_state">
              <el-select v-model="formModel.merchant_state" placeholder="请选择...">
                <el-option label="正常" :value="1" />
                <el-option label="冻结" :value="0" />
              </el-select>
            </el-form-item>
            <el-form-item label="OMS应用版本：" prop="oms_service_version">
              <el-select v-model="formModel.oms_service_version" placeholder="请选择...">
                <el-option label="不显示" :value="0" />
                <el-option label="基础版" :value="1" />
                <el-option label="标准版" :value="2" />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon><span>取消</span></el-button>
    </template>
  </el-drawer>
</template>

<script>
import { Check, Close } from '@element-plus/icons-vue'
import { apiGetMerchantsObject, apiInsertMerchants, apiUpdateMerchants } from '@/api/merchant.js';

export default {
  name: 'merchants-edit-view',
  components: {
    Check, Close,
  },
  // props: [],
  emits: ['submit'],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      merchant_id: '',
      formModel: {
        merchant_id: '',
        merchant_name: '',
        merchant_code: '',
        ref_key: '',
        merchant_state: 1,
        oms_service_version: 0,
      },

      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        // staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
        // goods_name: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'merchant_name', 'merchant_code', 'ref_key', {key: 'merchant_state', default: 1}, {key: 'oms_service_version', default: 0}, 
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.merchant_id = id
      this.viewVisible = true
    },
    
    // 当View打开时
    onViewOpen() {
      if (this.editMode === 'update') {
        const loadingInstance = this.$loading({})
        apiGetMerchantsObject({
          merchant_id: this.merchant_id,
        }).then((res) => {
          this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
          loadingInstance.close()
        })
      } else {
        this.formModel = this.$$utils.cloneObject({}, this.cloneFields)
        this.merchant_id = ''
      }
    },
    // 点击提交按钮
    onSubmit() {
      // this.$refs['form'].validate((validResult) => {
      //   if (validResult === false) {
      //     this.$message.error('请正确填写表单数据'); return
      //   } 
        
        let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
        formData.merchant_id = this.merchant_id
        formData.shop_id = this.shop_id

        if (this.editMode === 'update') {
          formData.merchant_id = this.merchant_id
          apiUpdateMerchants(formData).then((res) => {
            this.viewVisible = false;
            this.$emit('submit')
          })
        } else {
          apiInsertMerchants(formData).then((res) => {
            this.viewVisible = false;
            this.$emit('submit')
          })
        }
      // });
    },


  },
  mounted() {
  }
};
</script>

<style scoped>
</style>
