<template>
  <el-tag :type="type" :effect="effect" :size="size" >
    <span class="ep-state-tag-icon" :style="iconColorStyle" />{{ label }}
  </el-tag>
</template>

<script>
export default {
  name: "ep-state-tag",
  components: {
  },  
  props: {
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'default'
    },
    effect: {
      type: String,
      default: 'plain'
    },
    label: {
      type: String,
    },
  },
  data() {
    return {  
    };
  },
  computed: {
    // Popover标题
    tagPopoverTitle: function() {
      return '支付申报：' + this.getStateText(this.value.payment_result)
    },
    // Popover内容
    tagPopoverContent: function() {
      return this.value.payment_message ? this.value.payment_message : '-暂无信息-'
    },
    // 标签文本
    tagText: function() {
      return (this.mode == 'highlight') ? this.getStateText(this.value.payment_result) : '支付'
    },
    // 标签样式
    tagEffect: function() {
      return (this.mode == 'highlight') ? 'dark' : 'light'
    },
    // 状态圆点款式
    stateType: function() {
      return this.getStateType(this.value.payment_result)
    },
    iconColorStyle: {
      get: function () {
        var _IconColor = '#909399'
        switch (this.type) {
          case 'primary':
            _IconColor = '#409EFF'
            break;
          case 'success':
            _IconColor = '#67C23A'
            break;
          case 'danger':
            _IconColor = '#F56C6C'
            break;
          case 'warning':
            _IconColor = '#E6A23C'
            break;
          case 'info':
            _IconColor = '#909399'
            break;
        }
        return `background:${_IconColor};`
      },
      set: function (newValue) {
      }
    },

  },
  watch: {
  },
  methods: {
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
};
</script>

<style scoped>
.ep-state-tag-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}
</style>
