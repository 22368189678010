<template>
  <el-dialog v-model="viewVisible" width="800px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">身份证上传：<span v-if="epIsLoading">加载中...</span><span v-else>{{ orderData.order_sn }}</span>
          <el-tag v-if="orderData.oms_order_type == 10" type="success">分销订单</el-tag><el-tag v-else-if="orderData.oms_order_type == 20" type="success">供应订单</el-tag>
        </span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <!-- <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button> -->
        </div>
      </div>
    </template>

    <el-skeleton :rows="8" :loading="epIsLoading" animated>
      <template #default>
        <!-- 主要内容区域 START --> 

        <el-row :gutter="20">
          <el-col :offset="4" :span="8"><div class="grid-content ep-bg-purple" />
            身份证正面（国徽面）：
            <el-upload name="imageFile" class="main-image-uploader"
              :action="uploadUrl" list-type="picture-card" :show-file-list="false" :multiple="false" accept="image/*" 
              :data="mainImageUploadData" :headers="{'x-request-token':'50f92b9ddec0e275a191997a790fdf96', 'x-timestamp':1413388800}"
              :before-upload="beforeUpload" :on-success="onUploadSuccess1">
              <img v-if="idcard_front_url" :src="idcard_front_url" class="m-main-image" />
              <el-icon v-else class="main-image-uploader-icon"><Plus /></el-icon>
            </el-upload>
            <div v-if="idcard_front_url"><el-button type="primary" link style="margin-left: 10px;" @click="onMainImageDelete1">删除</el-button></div>
          </el-col>

          <el-col :offset="2" :span="8"><div class="grid-content ep-bg-purple" />
            身份证背面（信息面）：
            <el-upload name="imageFile" class="main-image-uploader"
              :action="uploadUrl" list-type="picture-card" :show-file-list="false" :multiple="false" accept="image/*" 
              :data="mainImageUploadData" :headers="{'x-request-token':'50f92b9ddec0e275a191997a790fdf96', 'x-timestamp':1413388800}"
              :before-upload="beforeUpload" :on-success="onUploadSuccess2">
              <img v-if="idcard_back_url" :src="idcard_back_url" class="m-main-image" />
              <el-icon v-else class="main-image-uploader-icon"><Plus /></el-icon>
            </el-upload>
            <div v-if="idcard_back_url"><el-button type="primary" link style="margin-left: 10px;" @click="onMainImageDelete2">删除</el-button></div>
          </el-col>

        </el-row>


        <!-- 主要内容区域 END --> 
      </template>
    </el-skeleton>

    <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template>
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Link, Check, Close } from '@element-plus/icons-vue'
import { apiV1GetOrderObject, apiV1UpdateOrder } from '@/api/trade.js'
import { apiV1ImageActionPreUpload } from '@/api/file.js'
import GlobalConfig from '@/config/index.js'

export default {
  mixins: [ DataTableMixin ],
  name: 'order-detail-dialog',
  components: {
    Link, Check, Close,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      order_id: '',
      orderData: [],


      // 检验规则
      formRules: {},
      // 拷贝规则
      cloneFields: [],

      uploadUrl: GlobalConfig.host + 'api/common/v1/file/image/actions/upload',
      mainImageUploadData: [],
      uploaderToken: '',

      idcard_front_url: '',
      idcard_back_url: '',
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      // this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {
      this.epIsLoading = true
      apiV1GetOrderObject({
        order_id: this.order_id,
      }).then((res) => {
        // this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
        this.orderData = res.data.data
        this.epIsLoading = false
        this.idcard_front_url = this.orderData.idcard_front_url
        this.idcard_back_url = this.orderData.idcard_back_url
      })
    },
    
    onSubmit() {
      // var newData = []
      // for (const element of this.goodsData) {
      //   newData.push(element.new_value)
      // }
      // newData[2] = this.mainImageUrl
      // // console.log(newValue); return

      // var lifecycle_data = {
      //   lifecycle_days: this.lifecycle_days,
      //   lifecycle_end_time: (this.lifecycle_end_time != null) ? parseInt(this.lifecycle_end_time.getTime() / 1000) : null
      // }

      // apiV1CaiyuanGoodsActionConfirm({
      //   id: this.id,
      //   new_data: newData,
      //   lifecycle_data: lifecycle_data,
      //   save_mode: save_mode,
      // }).then((res) => {
      //   if (res.data.code == 0) {
      //     this.$message.success("操作成功")
      //     this.viewVisible = false;
      //     this.$emit('submit')
      //   } else {
      //     this.$message.error("添加失败。" + res.data.message)
      //   }
        
      // })

      let formData = {
        order_id: this.order_id,
        idcard_front_url: this.idcard_front_url,
        idcard_back_url: this.idcard_back_url,
      }

      apiV1UpdateOrder(formData).then((res) => {
        this.viewVisible = false;
        this.$emit('submit')
      })
    },

    // ===================================================================================================

    // 图片上传前触发
    beforeUpload(rawFile) {
      if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('上传图片大小不能超过2MB!')
        return false
      }
    },
    // 图片上传成功
    onUploadSuccess1(response, uploadFile, uploadFiles) {
      if (response.code == -1) {
        this.$message.error('上传图片失败。' + response.msg)
      } else {
        this.idcard_front_url = response.url
      }
    },
    onMainImageDelete1() {
      this.$confirm("确定删除正面图片吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.idcard_front_url = ''
      })
    },
    onUploadSuccess2(response, uploadFile, uploadFiles) {
      if (response.code == -1) {
        this.$message.error('上传图片失败。' + response.msg)
      } else {
        this.idcard_back_url = response.url
      }
    },
    onMainImageDelete2() {
      this.$confirm("确定删除图片吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.idcard_back_url = ''
      })
    },

    // 初始化主图上传框
    initMainImageUploader() {
      if (this.uploaderToken == '') {
        apiV1ImageActionPreUpload().then((res) => {
          this.uploaderToken = res.data.token;
          this.mainImageUploadData = {
            token: this.uploaderToken,
            prefix: 'idwatermark',
          }
        })
      } else {
        this.mainImageUploadData = {
          token: this.uploaderToken,
          prefix: 'idwatermark',
        }
      }
    },

    // ===================================================================================================

    checkImageUrl(url) {
      return (url == '' || url == null) ? '/static/images/default_image.png' : url
    },

  },
  mounted() {
    this.$nextTick(() => {
      this.initMainImageUploader()
    })
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m-amount-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #303133; */
}
.m-discount-text {
  color: #67C23A;
}
.m-total-text {
  color: #f56C6C;
  font-weight: 700;
}

.m-main-image{ width:100px; height:60px; margin: 4px 0; }
.m-main-image .el-image { padding: 4px;  max-width: 100px;  max-height: 60px;  width: 100%;  height: 60px; }
.m-main-image .image-slot { display: flex;  justify-content: center;  align-items: center;  width: 100%;  height: 100%;  background: #d4d7de;  color: var(--el-text-color-secondary);  font-size: 30px; }
.m-main-image .image-slot .el-icon { font-size: 30px; }
.m-has-added { color:#67C23A; font-weight: 800; }
.main-image-uploader .el-upload {  border: 1px dashed var(--el-border-color);  border-radius: 6px;  cursor: pointer;  position: relative;  overflow: hidden;  transition: var(--el-transition-duration-fast);}
.main-image-uploader .el-upload:hover {  border-color: var(--el-color-primary);}
.el-icon.main-image-uploader-icon {  font-size: 28px;  color: #8c939d;  width: 148px;  height: 148px;  text-align: center;}
</style>
