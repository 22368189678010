<template>
  <el-drawer v-model="viewVisible" direction="rtl" size="800px" class="ep-drawer" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">
    <template #header><span>订单信息编辑：{{ order_sn }}</span></template>
    <div class="ep-drawer-wrap">
      <div class="ep-drawer-panel">
        <el-form ref="form" :model="formModel" label-width="200px" v-loading="isLoading" status-icon :rules="formRules">
          <div class="ep-drawer-region-title">基础信息</div>
          <div class="ep-drawer-region-wrap">

            <el-form-item label="支付人姓名：" prop="payer_name">
              <el-input v-model="formModel.payer_name" placeholder="请填写支付人姓名" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="支付人身份证：" prop="payer_idnumber">
              <el-input v-model="formModel.payer_idnumber" placeholder="请填写支付人身份证" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="支付人电话：" prop="payer_telephone">
              <el-input v-model="formModel.payer_telephone" placeholder="请填写支付人电话" style="width:300px;"/>
            </el-form-item>

            <div class="es-form-separation" />

            <el-form-item label="收货人姓名：" prop="consignee_name">
              <el-input v-model="formModel.consignee_name" placeholder="请填写收货人姓名" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="收货人电话：" prop="consignee_telephone">
              <el-input v-model="formModel.consignee_telephone" placeholder="请填写收货人电话" style="width:300px;"/>
            </el-form-item>

            <div class="es-form-separation" />

            <el-form-item label="收货人-省：" prop="consignee_addr_province_text">
              <el-input v-model="formModel.consignee_addr_province_text" placeholder="请填写收货人省" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="收货人-市：" prop="consignee_addr_city_text">
              <el-input v-model="formModel.consignee_addr_city_text" placeholder="请填写收货人市" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="收货人-区：" prop="consignee_addr_district_text">
              <el-input v-model="formModel.consignee_addr_district_text" placeholder="请填写收货人区" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="收货人-邮政编码：" prop="consignee_addr_area_code">
              <el-input v-model="formModel.consignee_addr_area_code" placeholder="请填写收货人邮政编码" style="width:300px;"/>
            </el-form-item>

            <el-form-item label="收货人-详细地址：" prop="consignee_short_address">
              <el-input v-model="formModel.consignee_short_address" placeholder="请填写收货人详细地址" style="width:300px;"/>
            </el-form-item>

            <!-- <el-form-item label="收货人-快速修改：" prop="consignee_quick_edit">
              <el-input v-model="formModel.consignee_quick_edit" placeholder="省,市,区,邮编,详细地址" style="width:240px;"/>
              <el-button type="default" @click="onQuickEditClick">识别</el-button>
            </el-form-item> -->

          </div>
        </el-form>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon><span>取消</span></el-button>
    </template>
  </el-drawer>
</template>

<script>
import { Check, Close } from '@element-plus/icons-vue'
import { apiV1GetOrderObject, apiV1UpdateOrder } from '@/api/trade.js'

export default {
  name: 'order-info-edit-view',
  components: {
    Check, Close,
  },
  props: [],
  emits: ['submit'],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      //
      order_id: '',
      order_sn: '',
      formModel: {
        // realweightqty: '',
        // cargo_length: '',
        // cargo_width: '',
        // cargo_height: '',
        payer_name: '',
        payer_idnumber: '',
        payer_telephone: '',
        consignee_name: '',
        consignee_telephone: '',
        consignee_addr_province_text: '',
        consignee_addr_city_text: '',
        consignee_addr_district_text: '',
        consignee_short_address: '',
        consignee_addr_area_code: '',
        //
        consignee_quick_edit: '',
      },
      //
      orderObj: {}, // 订单对象

      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        // staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
        // goods_name: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'payer_name', 'payer_idnumber', 'payer_telephone',
        'consignee_name', 'consignee_telephone',
        'consignee_addr_province_text', 'consignee_addr_city_text', 'consignee_addr_district_text', 'consignee_short_address', 'consignee_addr_area_code',
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      this.viewVisible = true
    },
    
    // 当View打开时
    onViewOpen() {
      this.order_sn = ''
      const loadingInstance = this.$loading({})
      apiV1GetOrderObject({
        order_id: this.order_id,
      }).then((res) => {
        this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
        this.order_sn = res.data.data.order_sn
        loadingInstance.close()
      })
    },
    // 点击提交按钮
    onSubmit() {
      this.$refs['form'].validate((validResult) => {
        if (validResult === false) {
          this.$message.error('请正确填写规格数据'); return
        }

        let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
        formData.order_id = this.order_id

        apiV1UpdateOrder(formData).then((res) => {
          this.viewVisible = false;
          this.$emit('submit')
        })
      });
    },

    // 快速修改
    onQuickEditClick() {
      var quickEditStr = this.formModel.consignee_quick_edit
      var quickEditArray = quickEditStr.split(',')

      if (quickEditArray.length == 5) {
        this.formModel.consignee_addr_province_text = quickEditArray[0]
        this.formModel.consignee_addr_city_text = quickEditArray[1]
        this.formModel.consignee_addr_district_text = quickEditArray[2]
        this.formModel.consignee_addr_area_code = quickEditArray[3]
        this.formModel.consignee_short_address = quickEditArray[4]
      }
    },

    // 获取商户数据pair
    // getMerchantPairData() {
    //   if (this.merchantPairs.length == 0) {
    //     apiV1GetMerchantsPairs().then((res) => {
    //       this.merchantPairs = res.data.data;
    //     })
    //   }
    // },
  },
  mounted() {
    // this.getMerchantPairData()
  }
};
</script>

<style scoped>
</style>
