import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 平台 - 微盟
// ================================================================================================

// 获取 平台订单分页
export function apiV1PlatformWeimobGetOrdersPage(data) {
    return request({url: `/api/admin/v1/platform/weimob/shops/${data.shop_id}/orders`, method: 'get', params: data, token: true})
}
// 获取 平台订单详情
export function apiV1PlatformWeimobGetOrderDetailPage(data) {
    return request({url: `/api/admin/v1/platform/weimob/shops/${data.shop_id}/orders/${data.order_sn}`, method: 'get', params: data, token: true})
}
// 操作 抓取指定订单
export function apiV1PlatformWeimobActionCatchOrder(data) {
    return request({url: `/api/admin/v1/platform/weimob/shops/${data.shop_id}/orders/${data.order_sn}/actions/catch`, method: 'post', data, token: true})
}

