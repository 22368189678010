<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>订单</el-breadcrumb-item><el-breadcrumb-item>平台订单管理</el-breadcrumb-item><el-breadcrumb-item>平台订单列表</el-breadcrumb-item><el-breadcrumb-item>微盟平台订单列表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddMerchantClick"><el-icon><edit/></el-icon>添加商户</el-button> -->
      </div>
    </el-header>
    <!-- 其他窗体 START -->
    <!-- <merchants-edit-view ref="refMerchantUpdateView" @submit="onEditSubmit" /> -->
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <!-- <div class="ep-table-header"> -->
      <!-- 搜索栏 START -->
      <OrderSearchView ref="searchView" @search="onSearched" @toggle="onSearchToggle"/>
      <!-- 搜索栏 END -->

      <el-table ref="esDataTable" fit border size="small" v-loading="esIsLoading" default-expand-all
        :data="esDataList" :height="esFixedTableHeight" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        :row-class-name="funRowClassName" :span-method="objectSpanMethod"
        row-key="entity_id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="onTableSelectionChange"> <!-- @row-click="onTableRowClick" -->

        <el-table-column type="selection" width="50" />

        <el-table-column label="主订单号" min-width="220">
          <template #default="scope">
            {{ scope.row.main_order_sn }}
            <div class="es-cell-subtitle"><span v-html="$$utils.toDataTime(scope.row.created)"></span></div>
            <div><el-tag>{{ scope.row.orderStatusName }}</el-tag></div>
          </template>
        </el-table-column>

        <el-table-column label="支付金额" min-width="100" ><!-- fixed -->
          <template #default="scope">
            {{ scope.row.paymentAmount }}
          </template>
        </el-table-column>

        <el-table-column label="商品标题" min-width="200" >
          <template #default="scope">
            {{ scope.row.goodsTitle }}
            <div>
              <!-- <el-tag type="success">SKU存在</el-tag> -->
            </div>
          </template>
        </el-table-column>

        <el-table-column label="SPU/规格编码" min-width="150" >
          <template #default="scope">
            <el-input size="small" :modelValue="scope.row.goodsCode" :readonly="true"><template #prepend>商品</template>{{ scope.row.goodsCode }}</el-input>
            <el-input size="small" :modelValue="scope.row.skuCode" :readonly="true"><template #prepend>规格</template></el-input>


                <!-- <el-input size="mini" :value="scope.row.outer_item_id"><template slot="prepend">商品编码</template></el-input>
              <el-tag type="info">商品编码：{{  }}</el-tag>
              <el-tag type="info">规格编码：{{ scope.row.outer_sku_id }}</el-tag>
            </div> -->
            <!-- <div class="es-cell-subtitle">{{ scope.row.outer_item_id }}</div>
            <div class="es-cell-subtitle">{{ scope.row.outer_sku_id }}</div> -->
          </template>
        </el-table-column>

        <!-- <el-table-column label="状态" min-width="150">
          <template #default="scope">
            <template v-if="scope.row.entity_type == 'shop'">
              <template v-if="scope.row.shop_state == '1'"><EpStateTag type="success" effect="light" label="正常"/></template>
              <template v-else><EpStateTag type="info" effect="light" label="冻结"/></template>
            </template>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" min-width="150" fixed="right">
          <template #default="scope">

            <span class="es-action-button primary" @click="onDetailClick(scope.row)">查看订单详情</span>
            <span class="ep-action-line"/>
            <span class="es-action-button primary" @click="onCatchClick(scope.row)">抓取订单</span>


            
            <!-- <span class="ep-action-line"/>
            <span class="es-action-button primary" @click="onUpdateClick(scope.row)">编辑</span>
            <span class="ep-action-line"/>
            <span class="es-action-button danger" @click="onDeleteClick(scope.row.merchant_id)">删除</span> -->
            <!-- <div> -->
              <!-- <el-dropdown @click="onMoreButtonClick">
                <span class="es-action-button primary">更多<el-icon class="el-icon--right"><arrow-down /></el-icon></span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{index: 'redeclare', id: scope.row.order_id}">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown> -->
            <!-- </div> -->
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="ep-page-footer">
      <div class="ep-page-footer-toolbar">
        <!-- <el-button type="primary" @click="onAddClick"><el-icon><edit/></el-icon>添加商户</el-button> -->
      </div>
      <div class="ep-page-footer-pager">
        <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import BasePageMixin from '@/mixin/BasePageMixin.js'
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown } from '@element-plus/icons-vue'
// import MerchantsEditView from '@/views/v1/merchant/merchants/MerchantsEditView'
import OrderSearchView from '@/views/v1/trade/platform_order/list/weimob/OrderSearchView'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import EpStateTag from '@/components/v1/common/EpStateTag'
import { apiV1PlatformWeimobGetOrdersPage, apiV1PlatformWeimobActionCatchOrder } from '@/api/platform_weimob.js' // apiDeleteMerchants

// import { Test1 } from '@/pages/test/Test1'

export default {
  mixins: [ BasePageMixin, DataTableMixin ],
  name: "shop-list-page",
  components: {
    Edit, 
    // ArrowDown,
    // MerchantsEditView,
    OrderSearchView,
    SourceCodeTag,
    EpStateTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',

      current_merchant_id: '',
      // 当前店铺id
      current_shop_id: '', // 32201908210407394928150064e55678

      activeName: 'first',
      epPageSize: 10,
    }
  },
  computed: {},
  methods: {
    getTableHeightFix() {
      return (typeof(this.$refs.searchView) === 'undefined') ? 290 : (176 + this.$refs.searchView.getViewHeight())
    },
    getFilter() {
      let data = {}
      if (this.shop_id) {
        data = {shop_id: this.shop_id }
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.esIsLoading = true

      apiV1PlatformWeimobGetOrdersPage(queryData).then((res) => {
        this.esDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.esIsLoading = false
      })
    },

    // ===================================================================================================

    // 点击 添加商户
    onAddMerchantClick(id) {
      // this.getPage()
      this.$refs.refMerchantUpdateView.showView({ editMode: "insert" })
    },
    // 点击 添加店铺
    onAddShopClick(id) {
      // this.getPage()
      this.$refs.refShopUpdateView.showView({ editMode: "insert" })
    },
    // 查看平台订单
    onOrderListClick(rowObj) {
      let newUrl;
      if (rowObj.entity_type == 'merchant') {
        newUrl = this.$router.resolve({ name: 'MerchantsDetailPage', params: { id: rowObj.merchant_id } })
      } else {
        newUrl = this.$router.resolve({ name: 'ShopDetailPage', params: { id: rowObj.shop_id } })
      }
      
      window.open(newUrl.href, '_blank')
      // this.$router.push({ name: 'MerchantsDetailPage', params: { id: id }})
    },
    // 点击修改
    onUpdateClick(rowObj) {
      if (rowObj.entity_type == 'merchant') {
        this.$refs.refMerchantUpdateView.showView({ editMode: "update", id: rowObj.merchant_id })
      } else {
        this.$refs.refShopUpdateView.showView({ editMode: "update", id: rowObj.shop_id })
      }
    },
    // 点击删除
    onDeleteClick(id) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiDeleteMerchants({
      //     merchant_id: id,
      //   }).then((res) => {
      //     this.getPage()
      //     this.$message.success("操作成功")
      //   })
      // }).catch(()=>{})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      this.$message.success("操作成功")
    },
    // 点击搜索后触发
    onSearched(data) {
      this.getPage()
    },
    onSearchToggle() {
      this._esSetTableHeight()
    },

    // 更多按钮触发
    onMoreButtonClick(commandObj) {
      // console.log(commandObj)
      switch (commandObj.index) {
        case 'redeclare':
          this.onDeclareClick([commandObj.id], 1)
          break;
      }
    },


    // 查看订单详情
    onDetailClick(rowObj) {
      let newUrl = this.$router.resolve({ name: 'WeimobOrderDetailPage', params: { id: this.shop_id, id2: rowObj.main_order_sn } })
      
      window.open(newUrl.href, '_blank')
    },
    // 点击 抓取
    onCatchClick(rowObj) {
      apiV1PlatformWeimobActionCatchOrder({
        shop_id: this.shop_id,
        order_sn: rowObj.main_order_sn,
      }).then((res) => {
        console.log(res)
        this.$message.success("操作成功")
      })
    },


    // ===================================================================================================

    // 实体行样式
    funRowClassName({  row,  rowIndex,}) {
      if (row.entity_type === 'merchant') {
        return 'jxgocs-merchant-row'
      } else if (row.entity_type === 'shop') {
        return 'jxgocs-shop-row'
      }
      return ''
    },
    // 合并行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(row); console.log(column); console.log(columnIndex)
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 5) {
        return {
          rowspan: row.mo_rowspan,
          colspan: row.mo_colspan,
        }
      }
    },

    
    // ===================================================================================================
    // TEST
    // ===================================================================================================

    onTableRowClick(row, column, event) {
      // console.log(row)
      // row.a = !row.a
    },




    // 获取校区pair
    // getCampusPairData() {
    //   apiGetCampusPairs().then((res) => {
    //     this.campusPairs = res.data.data
    //   })
    // },

  },
  watch: {},
  mounted() {
    this.shop_id = this.$route.params.id
    this.$nextTick(() => {
      // this.getPage()
    })
  }
}
</script>

<style scoped>
</style>
