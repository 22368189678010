
<template>
  <!-- 表格 START -->
  <div class="es-table-content">
    <div style="padding:20px;">
    <!-- <el-card class="box-card"> -->
      <el-form label-position="right" inline class="demo-table-expand" label-width="200px">

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="下单/支付人姓名：">
              <span>{{ row.payer_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下单/支付人电话：">
              <span>{{ row.payer_telephone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下单/支付人身份证：">
              <span>{{ row.payer_idnumber }}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="收货人姓名：">
              <span>{{ row.consignee_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货人电话：">
              <span>{{ row.consignee_telephone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货人身份证：">
              <span>{{ row.consignee_idnumber }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        
        <div class="es-form-separation" />

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="收货人区域：">
              <span>{{ row.consignee_addr_province_text }} - {{ row.consignee_addr_city_text }} - {{ row.consignee_addr_district_text }} （{{ row.consignee_addr_area_code }}）</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人短地址：">
              <span>{{ row.consignee_short_address }}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="收货人完整地址：">
              <span>{{ row.consignee_address }} </span>
            </el-form-item>
          </el-col>
        </el-row>  
        
        <div class="es-form-separation" />

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="支付金额：">
              <span>{{ row.pay_amount }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单金额：">
              <span>{{ row.order_amount }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="抵扣金额：">
              <span>{{ row.deductible_amount }}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="商品金额：">
              <span>{{ row.goods_amount }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="税费：">
              <span>{{ row.cat_tax }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="运费：">
              <span>{{ row.shipping_fee }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="es-form-separation" />

      <el-table ref="esDataTable" fit stripe border highlight-current-row :header-cell-style="{background:'#79bbff',color:'#fefefe'}" :data="row.orderGoods">
        
        <!-- <el-table-column type="selection" width="50" /> -->
        <el-table-column type="index" width="50" />
        <!-- <el-table-column label="子订单号" min-width="100" > -->
          <!-- fixed -->
          <!-- <template slot-scope="scope"> -->
            <!-- {{ scope.row.sub_order_sn }} -->
            <!-- <div class="es-cell-subtitle">{{ scope.row.goods_id }}</div> -->
          <!-- </template> -->
        <!-- </el-table-column> -->

        <!-- <el-table-column prop="goods_sku" label="SKU规格编码" min-width="100" />
        <el-table-column prop="yz_sku_id" label="有赞商品SKU" min-width="100" />

        <el-table-column prop="origin_code_name" label="进口地" min-width="100" />
        <el-table-column prop="unit_code_name" label="单位" min-width="100" /> -->


        <!-- <el-table-column label="备案信息" min-width="200" >
          <template slot-scope="scope">
            <div>海关备案号：{{ scope.row.cus_goods_no }}</div>
            <div>国检备案号：{{ scope.row.ciq_goods_no }}</div>
            <div>海关HSCode：{{ scope.row.hs_code }}</div>
          </template>
        </el-table-column> -->

        <!-- <el-table-column prop="sku_name" label="商品名称" min-width="200" /> -->
        <el-table-column label="商品名称" min-width="200" >
          <template #default="scope">
            {{ scope.row.sku_name }}
            <div v-if="scope.row.declaration_tag_name != ''"><el-tag type="success">{{ scope.row.declaration_tag_name }}</el-tag></div>
            <!-- <div class="es-cell-subtitle">{{ scope.row.goods_id }}</div> -->
          </template>
        </el-table-column>

        <el-table-column prop="goods_sn" label="商品编码" min-width="100" />
        <el-table-column prop="goods_sku" label="规格编码" min-width="100" />
        <el-table-column prop="price" label="商品单价" min-width="100" />
        <el-table-column prop="quantity" label="数量" min-width="100" />
        <!-- <el-table-column prop="pay_amount" label="付款金额" min-width="100" /> -->
        
        <!-- <el-table-column label="操作" min-width="150" >
          <template slot-scope="scope">
            <span class="es-action-button primary" @click="onDetailClick(scope.row.staff_id)">查看</span>
            <span class="es-action-button primary" @click="onUpdateClick(scope.row)">编辑</span>
            <span class="es-action-button danger" @click="onDeleteSkuClick(scope.row)">删除</span>
          </template>
        </el-table-column> -->
      </el-table>

    <!-- </el-card> -->
    </div>
  </div>
  <!-- 表格 END -->
</template>

<script>
export default {
  name: "es-pagination",
  props: [
    'row'
  ],
  data() {
    return {
      // skus: [],
    };
  },
  methods: {
    // 点击搜索按钮
    onSearchClick() {
      this.$emit('search', {})
    },
    // 获取搜索filter
    getSearchFilter() {
      // let searchFilter = {}
      // if (this.searchKey.trim() != '' && this.searchContent.trim() != '') {
      //   searchFilter = {[this.searchKey]: this.searchContent}
      // }
      // return searchFilter
    },
  },
  mounted() {
    console.log(this.row)
  },
  watch: {
  }
};
</script>

<style scoped>
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    /* width: 50%; */
  }
</style>
