<template>
  <el-dialog v-model="viewVisible" width="1200px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">订单分拆：<span v-if="epIsLoading">加载中...</span><span v-else>{{ orderData.order_sn }}</span>
          <el-tag v-if="orderData.oms_order_type == 10" type="success">分销订单</el-tag><el-tag v-else-if="orderData.oms_order_type == 20" type="success">供应订单</el-tag>
        </span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <!-- <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button> -->
        </div>
      </div>
    </template>

    <el-skeleton :rows="8" :loading="epIsLoading" animated>
      <template #default>
        <!-- 主要内容区域 START --> 

        <el-row :gutter="10">
          <el-col :span="14">
            <el-card>
              <template #header>

                <div style="padding: 20px">
                  <div class="m-amount-row"><span>商品总价：</span><span>¥ {{ orderData.goods_amount }}</span></div>
                  <div class="m-amount-row"><span>税费：</span><span>¥ {{ orderData.cat_tax }}</span></div>
                  <div class="m-amount-row"><span>运费：</span><span>¥ {{ orderData.shipping_fee }}</span></div>
                  <div class="m-amount-row"><span>优惠：</span><span class="m-discount-text">¥ {{ orderData.deductible_amount }}</span></div>
                  <el-divider />
                  <div class="m-amount-row"><span>实际支付：</span><span class="m-total-text">¥ {{ orderData.pay_amount }}</span></div>
                </div>


              </template>

              <!-- 商品信息 START -->
              <el-table ref="esDataTable" fit stripe highlight-current-row v-loading="epIsLoading" size="small"
                :data="oldSkus" :header-cell-style="{background:'#79bbff',color:'#fefefe'}">

                <el-table-column type="index" width="50" />
                
                <el-table-column label="商品名称 / 编码" min-width="200" >
                  <template #default="scope">
                    <template v-if="orderData.oms_order_type == 20"><span>{{ scope.row.goods_item_name }}</span></template>
                    <template v-else><span>{{ scope.row.sku_name }}</span></template>
                    <div>
                      <template v-if="orderData.oms_order_type == 20">
                        <el-table-column prop="goods_item_code" label="货品编码" min-width="100" />
                        <el-tag effect="plain">{{ scope.row.goods_item_code }}</el-tag>
                      </template>
                      <template v-else>
                        <el-tag effect="plain">{{ scope.row.goods_sn }}</el-tag> / <el-tag effect="plain">{{ scope.row.goods_sku }}</el-tag>
                      </template>
                    </div>
                  </template>
                </el-table-column>
                
                <!-- <template v-if="orderData.oms_order_type == 20">
                  <el-table-column prop="goods_item_code" label="货品编码" min-width="100" />
                </template>
                <template v-else>
                  <el-table-column label="商品/规格编码" min-width="120" >
                    <template #default="scope">
                      <span>{{ scope.row.goods_sn }}</span>
                      <div><span>{{ scope.row.goods_sku }}</span></div>
                    </template>
                  </el-table-column>
                </template>-->

                <el-table-column prop="price" label="单价" min-width="100" />
                <el-table-column prop="quantity" label="总数量" min-width="80" />

                <el-table-column label="拆分数量" min-width="120" >
                  <template #default="scope">
                    <el-input-number v-model="scope.row.newQuantity" size="small" :controls="false" :step="1" :step-strictly="true"/>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 商品信息 END -->
              <div class="m-split-wrap">
                <el-button type="primary" class="m-split-button" @click="onSplitClick" ><span>分拆</span><el-icon><DArrowRight/></el-icon></el-button>
              </div>
            </el-card>

          </el-col>

          <!-- 价格信息 START -->
          <el-col :span="10">
            <div style="padding: 10px; background: #fff;">

              <!-- <div><span>拆分结果：</span></div> -->

              <div v-show="newOrderList.length == 0">
                <el-empty description="暂无数据" />
              </div>

              <el-card class="m-new-order-card" v-for="(newOrder, index) in newOrderList">
                <template #header>
                  <div class="m-new-order-card-header">
                    <span>子订单：{{ orderData.order_sn }}-{{ index + 1 }}</span>
                    <el-button class="button" type="danger" @click="onCardDeleteClick(index)">删除</el-button>
                  </div>
                </template>
                <el-table ref="esDataTable" fit stripe highlight-current-row v-loading="epIsLoading" size="small"
                :data="newOrder" :header-cell-style="{background:'#79bbff',color:'#fefefe'}">

                  <el-table-column type="index" width="50" />
                  
                  <el-table-column label="商品名称 / 编码" min-width="200" >
                    <template #default="scope">
                      <template v-if="orderData.oms_order_type == 20"><span>{{ scope.row.goods_item_name }}</span></template>
                      <template v-else><span>{{ scope.row.sku_name }}</span></template>
                      <div>
                        <template v-if="orderData.oms_order_type == 20">
                          <el-table-column prop="goods_item_code" label="货品编码" min-width="100" />
                          <el-tag effect="plain">{{ scope.row.goods_item_code }}</el-tag>
                        </template>
                        <template v-else>
                          <el-tag effect="plain">{{ scope.row.goods_sn }}</el-tag> / <el-tag effect="plain">{{ scope.row.goods_sku }}</el-tag>
                        </template>
                      </div>
                    </template>
                  </el-table-column>
                  
                  <el-table-column prop="price" label="单价" min-width="100" />
                  <el-table-column prop="quantity" label="数量" min-width="80" />


                </el-table>

              </el-card>



            </div>
          </el-col>
          <!-- 价格信息 END -->
          
        </el-row>
        <!-- 主要内容区域 END --> 
      </template>
    </el-skeleton>

    <template #footer>
      <el-button type="danger" @click="onSubmit"><el-icon><Refresh/></el-icon><span>重置</span></el-button>

      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template>
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Link, Check, Close, DArrowRight, Refresh } from '@element-plus/icons-vue'
import { apiV1GetOrderObject, apiV1SplitOrder } from '@/api/trade.js'


export default {
  mixins: [ DataTableMixin ],
  name: 'order-detail-dialog',
  components: {
    Link, Check, Close, DArrowRight, Refresh,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      order_id: '',
      orderData: [],


      // 检验规则
      formRules: {
        name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        {key: 'sex', default: 0},
      ],


      // 
      newOrderList: [],

      oldSkus: [],

    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      // this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {

      this.epIsLoading = true

      apiV1GetOrderObject({
        order_id: this.order_id,
      }).then((res) => {
        // this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
        this.orderData = res.data.data
        this.newOrderList = []
        this.oldSkus = []
        for (var i = 0; i < this.orderData.orderGoods.length; i++) {
          this.oldSkus.push({
            sku_id: this.orderData.orderGoods[i]['sku_id'],
            goods_id: this.orderData.orderGoods[i]['goods_id'],
            goods_item_id: this.orderData.orderGoods[i]['goods_item_id'],
            //
            goods_item_name: this.orderData.orderGoods[i]['goods_item_name'],
            sku_name: this.orderData.orderGoods[i]['sku_name'],
            goods_item_code: this.orderData.orderGoods[i]['goods_item_code'],
            goods_sn: this.orderData.orderGoods[i]['goods_sn'],
            goods_sku: this.orderData.orderGoods[i]['goods_sku'],
            // 
            price: this.orderData.orderGoods[i]['price'],
            quantity: this.orderData.orderGoods[i]['quantity'],
            newQuantity: 0,
            remainingQuantity: this.orderData.orderGoods[i]['quantity'],
          })
        }

        this.epIsLoading = false
      })
    },

    // 点击 拆分
    onSplitClick() {
      var newOrderSkus = []
      var hasSplit = false
      for (var i = 0; i < this.oldSkus.length; i++) {
        if (this.oldSkus[i]['newQuantity'] != 0) {
          newOrderSkus.push({
            sku_id: this.oldSkus[i]['sku_id'],
            goods_id: this.oldSkus[i]['goods_id'],
            goods_item_id: this.oldSkus[i]['goods_item_id'],
            //
            goods_item_name: this.oldSkus[i]['goods_item_name'],
            sku_name: this.oldSkus[i]['sku_name'],
            goods_item_code: this.oldSkus[i]['goods_item_code'],
            goods_sn: this.oldSkus[i]['goods_sn'],
            goods_sku: this.oldSkus[i]['goods_sku'],
            // 
            price: this.oldSkus[i]['price'],
            quantity: this.oldSkus[i]['newQuantity'],
          })

          hasSplit = true
        }
      }

      if (hasSplit) { this.newOrderList.push(newOrderSkus) }
      
      console.log(this.newOrderList)

    },



    // 子订单 删除
    onCardDeleteClick(id) {
      // console.log(id)
      this.newOrderList.splice(id, 1)
    },


    onSubmit() {
      // let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
      // formData.shop_id = this.shop_id
      // formData.start_time = parseInt(this.start_time.getTime() / 1000)
      // formData.expire_time = parseInt(this.expire_time.getTime() / 1000)


      let formData = { order_id: this.orderData.order_id, new_order_list: this.newOrderList }

      apiV1SplitOrder(formData).then((res) => {
        if (res.data.code != 0) {
          this.$message.error('请正确填写拆分数据'); return
        } else {
          this.viewVisible = false; this.$emit('submit')
        }
        // this.viewVisible = false; this.$emit('submit')
      })

      // if (this.editMode === 'update') {
      //   formData.shop_id = this.shop_id
        
      // } else {
      //   apiV1InsertShop(formData).then((res) => {
      //     this.viewVisible = false;
      //     this.$emit('submit')
      //   })
      // }
    },
    

    // ===================================================================================================

    // 获取校区pair
    // getCampusPairData() {
    //   if (this.campusPairs.length == 0) {
    //     apiGetCampusPairs().then((res) => {
    //       this.campusPairs = res.data.data;
    //     })
    //   }
    // },

  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m-amount-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #303133; */
}
.m-discount-text {
  color: #67C23A;
}
.m-total-text {
  color: #f56C6C;
  font-weight: 700;
}

.m-split-wrap { padding-top: 20px;}
.m-split-button { display: block; width: 200px; margin-left: auto; margin-right: auto;}
/* */
.m-new-order-card { margin: 10px 0;}
.m-new-order-card-header { display: flex; justify-content: space-between; align-items: center;}
</style>
