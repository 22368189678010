<template>
  <el-drawer v-model="viewVisible" direction="rtl" size="800px" custom-class="ep-drawer" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">
    <template #title><span>编辑：店铺</span></template>
    <div class="ep-drawer-wrap">
      <div class="ep-drawer-panel">
        <el-form ref="form" :model="formModel" label-width="200px" v-loading="isLoading" status-icon :rules="formRules">
          <div class="ep-drawer-region-title">基础信息</div>
          <div class="ep-drawer-region-wrap">
            <el-form-item label="店铺名称：" prop="shop_name">
              <el-input v-model="formModel.shop_name" />
            </el-form-item>

            <el-form-item label="店铺编码：" prop="shop_code">
              <el-input v-model="formModel.shop_code" />
            </el-form-item>

            <el-form-item label="所属商户：" prop="ref_key">
              <el-select v-model="formModel.merchant_id" placeholder="请选择...">
                <el-option v-for="item in merchantPairs" :key="item.merchant_id" :label="item.merchant_name" :value="item.merchant_id">
                  <span class="es-select-option-left">{{ item.merchant_name }}</span>
                  <!-- <span class="es-select-option-right">{{ item.merchant_code }}</span> -->
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="来源编码：" prop="source_code">
              <el-select v-model="formModel.source_code" placeholder="请选择...">
                <el-option label="有赞平台" value="YouzanPlatform" />
                <el-option label="拼多多平台" value="PddPlatform" />
                <el-option label="洋码头平台" value="YmatouPlatform" />
                <el-option label="微盟平台" value="WeimobPlatform" />
                <el-option label="微店平台" value="WeidianPlatform" />
              </el-select>
            </el-form-item>

            <el-form-item label="关联店铺ID：" prop="ref_key">
              <el-input v-model="formModel.ref_key" />
            </el-form-item>

            <div class="es-form-separation" />

            <el-form-item label="店铺状态：" prop="shop_state">
              <el-select v-model="formModel.shop_state" placeholder="请选择...">
                <el-option label="正常" :value="1" />
                <el-option label="冻结" :value="0" />
              </el-select>
            </el-form-item>

            <el-form-item label="生效起始日期：" prop="start_time">
              <el-date-picker v-model="start_time" type="datetime" align="right"/>
            </el-form-item>

            <el-form-item label="生效结束日期：" prop="expire_time">
              <el-date-picker v-model="expire_time" type="datetime" align="right"/>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><close/></el-icon><span>取消</span></el-button>
    </template>
  </el-drawer>
</template>

<script>
import { Check, Close } from '@element-plus/icons-vue'
import { apiGetMerchantsPairs, apiGetShopObject, apiInsertShop, apiUpdateShop } from '@/api/merchant.js';

export default {
  name: 'shop-edit-view',
  components: {
    Check, Close,
  },
  props: [],
  emits: ['submit'],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      shop_id: '',
      formModel: {
        shop_id: '',
        shop_name: '',
        shop_code: '',
        merchant_id: '',
        source_code: '',
        ref_key: '',
        shop_state: 1,
      },
      start_time: '',
      expire_time: '',


      merchantPairs: [],

      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        // staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
        // goods_name: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'shop_name', 'shop_code', 'merchant_id', 'source_code', 'ref_key',
        {key: 'shop_state', default: 1}
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.shop_id = id
      this.viewVisible = true
    },
    
    // 当View打开时
    onViewOpen() {
      if (this.editMode === 'update') {
        const loadingInstance = this.$loading({})
        apiGetShopObject({
          shop_id: this.shop_id,
        }).then((res) => {
          this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)

          this.start_time = new Date(res.data.data.start_time * 1000)
          this.expire_time = new Date(res.data.data.expire_time * 1000)

          loadingInstance.close()
        })
      } else {
        this.formModel = this.$$utils.cloneObject({}, this.cloneFields)
        this.shop_id = ''
        this.start_time = new Date()
        this.expire_time = new Date()
      }
    },
    // 点击提交按钮
    onSubmit() {
      // this.$refs['form'].validate((validResult) => {
      //   if (validResult === false) {
      //     this.$message.error('请正确填写表单数据'); return
      //   } 
        
        let formData = this.$$utils.cloneObject(this.formModel, this.cloneFields)
        formData.shop_id = this.shop_id
        formData.start_time = parseInt(this.start_time.getTime() / 1000)
        formData.expire_time = parseInt(this.expire_time.getTime() / 1000)

        if (this.editMode === 'update') {
          formData.shop_id = this.shop_id
          apiUpdateShop(formData).then((res) => {
            this.viewVisible = false;
            this.$emit('submit')
          })
        } else {
          apiInsertShop(formData).then((res) => {
            this.viewVisible = false;
            this.$emit('submit')
          })
        }
      // });
    },

    // 获取商户数据pair
    getMerchantPairData() {
      if (this.merchantPairs.length == 0) {
        apiGetMerchantsPairs().then((res) => {
          this.merchantPairs = res.data.data;
        })
      }
    },
  },
  mounted() {
    this.getMerchantPairData()
  }
};
</script>

<style scoped>
</style>
