<template>
  <el-form class="ep-search-form" ref="searchForm" :model="searchModel" label-width="100px">
    <div class="m-common-field">
      <div class="ep-search-form-item-row">
        <!-- <div style="width:420px">
          <el-form-item label="订单搜索：">
            <el-input placeholder="请输入内容" v-model="searchContent" class="es-input-with-select">
              <template #prepend>
                <el-select v-model="searchKey" placeholder="请选择">
                  <el-option label="商户名称" value="merchant_name" />
                  <el-option label="商户编码" value="merchant_code" />
                </el-select>
              </template>
            </el-input>
          </el-form-item>
        </div> -->

        <div>
          <el-form-item label="创建时间：">
            <el-date-picker
              v-model="searchModel.createdTime"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="ep-search-form-item-col1">
          <el-form-item label="订单状态：">
            <el-select v-model="searchModel.status" placeholder="请选择...">
              <el-option label="1-待发货" value="1" />
              <el-option label="全部" value="__ALL__" />
              <el-option label="0-待支付" value="0" />
              <el-option label="2-已发货" value="2" />
              <el-option label="3-已完成" value="3" />
              <el-option label="4-已取消" value="4" />
            </el-select>
          </el-form-item>
        </div>

        
      </div>
    </div>
    <!-- <div class="m-advance-filed" v-show="this.advanceToggle">
      <div class="ep-search-form-item-row">
        <div class="ep-search-form-item-col1">
          <el-form-item label="商户编码：">
            <el-input v-model="searchModel.merchant_code" />
          </el-form-item>
        </div>
        <div class="ep-search-form-item-col1">
          <el-form-item label="商户编码：">
            <el-input v-model="searchModel.merchant_code" />
          </el-form-item>
        </div>
        <div class="ep-search-form-item-col1">
          <el-form-item label="商户编码：">
            <el-input v-model="searchModel.merchant_code" />
          </el-form-item>
        </div>
      </div>
    </div> -->
    <div class="ep-search-form-button-row">
      <el-button type="primary" @click="onSearchClick">搜索</el-button>
      <!-- <el-button @click="onAdvanceToggle">高级搜索</el-button> -->
    </div>
 
  </el-form>
</template>

<script>
export default {
  name: "order-search-view",
  components: {
  },
  props: [
    'searchKeys'
  ],
  emits: ['search', 'toggle'],
  data() {
    return {
      // 商品搜索下拉框值
      searchKey: '',
      // 搜索值
      searchContent: '',
      searchModel: {
        // merchant_name: '',
        // merchant_code: '',

        createdTime: [],
        status: '1',
      },

      advanceToggle: false,

      // 时间选择快捷方式
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      
    };
  },
  watch: {
  },
  methods: {
    // 获取组件高度
    getViewHeight() {
      return this.advanceToggle ? 164 : 114
    },
    // 获取搜索filter
    getSearchFilter() {
      // let searchFilter = {}
      // if (this.searchKey.trim() != '' && this.searchContent.trim() != '') {
      //   searchFilter = {[this.searchKey]: this.searchContent}
      // }

      var start_created = parseInt(this.searchModel.createdTime[0].getTime() / 1000)
      var end_created = parseInt(this.searchModel.createdTime[1].getTime() / 1000)

      let searchFilter = {
        start_created: start_created,
        end_created: end_created,
        status: this.searchModel.status,
      }

      return searchFilter
    },
    // 点击搜索按钮
    onSearchClick() {
      var searchData = this.getSearchFilter()
      this.$emit('search', searchData)
    },
    // 切换搜索模式时触发
    onAdvanceToggle() {
      // console.log(this.advanceToggle)
      this.advanceToggle = !this.advanceToggle
      this.$emit('toggle', this.advanceToggle)
    }
    // ================================================================================================
  },
  mounted() {
    // console.log(this)
    // 初始化时间 最近24小时
    const createdTimeStart = new Date();
    createdTimeStart.setTime(createdTimeStart.getTime() - 3600 * 1000 * 24);
    this.searchModel.createdTime = [createdTimeStart, new Date()]
  }
};
</script>

<style scoped>
</style>
