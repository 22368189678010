<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>订单</el-breadcrumb-item><el-breadcrumb-item>平台订单管理</el-breadcrumb-item><el-breadcrumb-item>平台订单列表</el-breadcrumb-item><el-breadcrumb-item>微盟平台订单列表</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddMerchantClick"><el-icon><edit/></el-icon>添加商户</el-button> -->
      </div>
    </el-header>
    <!-- 其他窗体 START -->
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <!-- <div class="ep-table-header"> -->
      <!-- 搜索栏 START -->
      <el-form class="ep-search-form" ref="searchForm" label-width="100px">
        <div class="m-common-field">
          <div class="ep-search-form-item-row">
            <div class="ep-search-form-item-col1">
              <el-form-item label="平台订单编号：">
                <el-input v-model="order_sn" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="ep-search-form-button-row">
          <el-button type="primary" @click="onSearchClick">搜索</el-button>
          <!-- <el-button @click="onAdvanceToggle">高级搜索</el-button> -->
        </div>
      </el-form>
      <!-- 搜索栏 END -->
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="格式化订单源信息" name="first">
          <template v-if="order_source_data_format_json == ''">
            <el-empty description="暂无信息" />
          </template>
          <template v-else>
            <div style="text-align:left;" v-html="`<pre>` + syntaxHighlight(order_source_data_format_json) + `</pre>`" />
          </template>  
        </el-tab-pane>
        <el-tab-pane label="订单源信息" name="second">
          <div>
            <el-input v-model="this.order_source_data" :rows="25" type="textarea" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-footer class="ep-page-footer">
      <div class="ep-page-footer-toolbar">
        <!-- <el-button type="primary" @click="onAddClick"><el-icon><edit/></el-icon>添加商户</el-button> -->
      </div>
      <div class="ep-page-footer-pager">
        <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import BasePageMixin from '@/mixin/BasePageMixin.js'
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown } from '@element-plus/icons-vue'
// import OrderSearchView from '@/views/v1/trade/platform_order/list/weimob/OrderSearchView'
// import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1PlatformWeimobGetOrderDetailPage } from '@/api/platform_weimob.js'


export default {
  mixins: [ BasePageMixin, DataTableMixin ],
  name: "order-detail-page",
  components: {
    Edit, 
    // ArrowDown,
    // OrderSearchView,
    // SourceCodeTag,
  },
  props: [],
  data() {
    return {
      shop_id: '',

      current_merchant_id: '',
      // 当前店铺id
      current_shop_id: '', // 32201908210407394928150064e55678

      activeName: 'first',
      epPageSize: 10,

      order_sn: '',
      order_format_data: '',
      order_source_data: '',
      order_source_data_format_json: '',
    }
  },
  computed: {},
  methods: {
    getTableHeightFix() {
      return (typeof(this.$refs.searchView) === 'undefined') ? 290 : (176 + this.$refs.searchView.getViewHeight())
    },
    getFilter() {
      let data = {}
      if (this.shop_id) {
        data = {shop_id: this.shop_id }
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.esIsLoading = true

      // apiV1PlatformWeimobGetOrdersPage(queryData).then((res) => {
      //   this.esDataList = res.data.data
      //   this.setPaginationTotal(res.data.pagination.total)
      //   this.esIsLoading = false
      // })
    },

    // ===================================================================================================

    // 点击 添加商户
    onAddMerchantClick(id) {
      // this.getPage()
      this.$refs.refMerchantUpdateView.showView({ editMode: "insert" })
    },
    // 点击 搜索
    onSearchClick() {
      apiV1PlatformWeimobGetOrderDetailPage({
        shop_id: this.shop_id,
        order_sn: this.order_sn,
      }).then((res) => {
        this.order_format_data = res.data.data.format_data
        this.order_source_data = res.data.data.source_data

        this.order_source_data_format_json = JSON.stringify(JSON.parse(this.order_source_data), null, 4)
        this.esIsLoading = false
      })
    },


    // ===================================================================================================

    // JSON格式化
    syntaxHighlight(json) {
      if (json == '') return
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
            var cls = 'm-number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'm-key';
                } else {
                    cls = 'm-string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'm-boolean';
            } else if (/null/.test(match)) {
                cls = 'm-null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    },


  },
  mounted() {
    this.shop_id = this.$route.params.id

    // console.log(this.$route.params.id2)
    if (this.$route.params.id2 === undefined) {
      this.order_sn = ''
    } else {
      this.order_sn = this.$route.params.id2
    }
    // console.log(this.order_sn)

    this.$nextTick(() => {
      // this.getPage()
    })
  }
}
</script>

<style>
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.m-string { color: green; }
.m-number { color: darkorange; }
.m-boolean { color: blue; }
.m-null { color: magenta; }
.m-key { color: red; }
</style>
