<template>
  <el-form class="ep-search-form" ref="searchForm" :model="searchModel" label-width="100px">
    <div class="m-common-field">
      <div class="ep-search-form-item-row">
        <div style="width:420px">
          <el-form-item label="商户搜索：">
            <el-input placeholder="请输入内容" v-model="searchContent" class="es-input-with-select">
              <template #prepend>
                <el-select v-model="searchKey" placeholder="请选择">
                  <el-option label="商户名称" value="merchant_name" />
                  <el-option label="商户编码" value="merchant_code" />
                </el-select>
              </template>
            </el-input>
          </el-form-item>
        </div>
      </div>
    </div>
    <div class="m-advance-filed" v-show="this.advanceToggle">
      <div class="ep-search-form-item-row">
        <div class="ep-search-form-item-col1">
          <el-form-item label="商户编码：">
            <el-input v-model="searchModel.merchant_code" />
          </el-form-item>
        </div>
        <div class="ep-search-form-item-col1">
          <el-form-item label="商户编码：">
            <el-input v-model="searchModel.merchant_code" />
          </el-form-item>
        </div>
        <div class="ep-search-form-item-col1">
          <el-form-item label="商户编码：">
            <el-input v-model="searchModel.merchant_code" />
          </el-form-item>
        </div>
      </div>
    </div>
    <div class="ep-search-form-button-row">
      <el-button type="primary" @click="onSearchClick">搜索</el-button>
      <el-button @click="onAdvanceToggle">高级搜索</el-button>
    </div>
 
  </el-form>
</template>

<script>
export default {
  name: "merchants-search-view",
  components: {
  },
  props: [
    'searchKeys'
  ],
  emits: ['search', 'toggle'],
  data() {
    return {
      // 商品搜索下拉框值
      searchKey: '',
      // 搜索值
      searchContent: '',


      searchModel: {
        merchant_name: '',
        merchant_code: '',
      },

      advanceToggle: false,
      
    };
  },
  methods: {
    // 获取组件高度
    getViewHeight() {
      return this.advanceToggle ? 164 : 114
    },
    // 获取搜索filter
    getSearchFilter() {
      let searchFilter = {}
      if (this.searchKey.trim() != '' && this.searchContent.trim() != '') {
        searchFilter = {[this.searchKey]: this.searchContent}
      }

      // var searchFilter = {}
      // if (this.searchModel.merchant_name != '') {
      //   searchFilter.merchant_name = this.searchModel.merchant_name
      // }
      // if (this.searchModel.merchant_code != '') {
      //   searchFilter.merchant_code = this.searchModel.merchant_code
      // }
      return searchFilter
    },
    // 点击搜索按钮
    onSearchClick() {
      var searchData = this.getSearchFilter()
      this.$emit('search', searchData)
    },
    // 切换搜索模式时触发
    onAdvanceToggle() {
      // console.log(this.advanceToggle)
      this.advanceToggle = !this.advanceToggle
      this.$emit('toggle', this.advanceToggle)
    }
    // ================================================================================================
  },
  mounted() {
    // console.log(this)
  },
  watch: {
  }
};
</script>

<style scoped>
</style>
